<template>
  <div class="home scroll-smooth">
    <NavbarSection />
    <HeroSection />
    <ProductsSection />
    <ValueSection />
    <FooterSection />
    <!-- <HelloWorld msg="Welcome to Your Vue.js App" /> -->
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";
import NavbarSection from "@/components/NavbarSection.vue";
import HeroSection from "@/components/HeroSection.vue";
import ProductsSection from "@/components/ProductsSection.vue";
import ValueSection from "@/components/ValueSection.vue";
import FooterSection from "@/components/FooterSection.vue";

export default {
  name: "HomeView",
  components: {
    HelloWorld,
    NavbarSection,
    HeroSection,
    ProductsSection,
    ValueSection,
    FooterSection,
},
};
</script>
