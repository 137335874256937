<template>
  <nav
    class=" top-0 sticky z-50 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg bg-white shadow-lg">
    <div class="container px-4 mx-auto flex flex-wrap items-center justify-between">
      <div class="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
        <router-link to="/">
          <div class="mr-4 navbar-brand">
            <img src="../assets/logo.svg" class="main-imasge w-3/4" alt="cardplux logo">
          </div>
        </router-link>
        <button
          class="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
          type="button" v-on:click="setNavbarOpen">
          <font-awesome-icon icon="fa-solid fa-bars"  style="color: #13338E;" />
        </button>
      </div>
      <div class="lg:flex flex-grow items-center" :class="[navbarOpen ? 'block' : 'hidden']" id="example-navbar-warning">
        <ul class="flex flex-col lg:flex-row gap-2 mt-5 md:mt-0 md:gap-6 list-none lg:ml-auto">

          <li class=" flex items-center nav-item active">
            <a class="hover:text-blue-500 text-gray-700 px-3 py-2 flex items-center text-sm font-medium uppercase font-dm page-scroll "
              href="/" >Home</a>
          </li>
          <li class=" flex items-center nav-item ">
            <a class="hover:text-blue-500 text-gray-700 px-3 py-2 flex items-center text-sm font-medium uppercase font-dm page-scroll "
              href="#products" v-smooth-scroll="{ duration: 600, offset: -100}">Products</a>
          </li>
          <li class=" flex items-center nav-item">
            <a class="hover:text-blue-500 text-gray-700 px-3 py-2 flex items-center text-sm font-medium uppercase font-dm page-scroll "
              href="#value" v-smooth-scroll="{ duration: 600, offset: -100}">Value</a>
          </li>
          <li class="flex items-center">

            <c-t-a text="Trade now" :class="[navbarOpen ? 'block' : 'hidden']" />
          </li>
        </ul>
      </div>
      <div class="lg:flex flex-grow items-center" :class="[navbarOpen ? 'hidden' : 'hidden']" id="example-navbar-warning">
        <ul class="flex flex-col lg:flex-row list-none lg:ml-auto">

          <li class="flex items-center">

            <c-t-a text="Trade now" />
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
  
<script>
// import IndexDropdown from "@/components/Dropdowns/IndexDropdown.vue";
import CTA from '@/components/CTA.vue';

export default {
  data() {
    return {
      navbarOpen: false,
    };
  },
  methods: {
    setNavbarOpen: function () {
      this.navbarOpen = !this.navbarOpen;
    },
  },
  components: { CTA }
};
</script>
  