<template>
    <div>
        <a class="text-white cursor-pointer font-semibold bg-[#00AEEF] px-5 flex items-center gap-4 py-3 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none lg:mb-0 ease-linear transition-all duration-150"
            href="https://wa.me/2348146177544" target="_blank">
            {{ text }}
            <font-awesome-icon icon="fa-solid fa-arrow-right" style="color: #ffffff;" />
        </a>
    </div>
</template>
<script>
export default {
    name: "CTA",
    props: {
        text: String,
        destination: String,
    },
};
</script>