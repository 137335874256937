<template>
    <svg class="absolute w-1/3" viewBox="0 0 496 480" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_f_119_30)">
            <circle cx="73.5" cy="57.5" r="230.5" fill="#7DB9F9" fill-opacity="0.4" />
        </g>
        <defs>
            <filter id="filter0_f_119_30" x="-348.64" y="-364.64" width="844.279" height="844.279"
                filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="95.8199" result="effect1_foregroundBlur_119_30" />
            </filter>
        </defs>
    </svg>

    <svg class="absolute w-1/3 right-0 top-3/4 md:top-8" viewBox="0 0 518 774" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_f_119_28)">
            <circle cx="387" cy="387" r="211" fill="#CD52C8" fill-opacity="0.5" />
        </g>
        <defs>
            <filter id="filter0_f_119_28" x="0.572754" y="0.572754" width="772.854" height="772.854"
                filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="87.7136" result="effect1_foregroundBlur_119_28" />
            </filter>
        </defs>
    </svg>

    <section class="grid grid-cols-1 md:grid-cols-2 items-center justify-between my-20 mx-2 md:mx-10 gap-y-20 md:gap-5">

        <div class="flex flex-col gap-6">
            <h1 class="text-5xl md:text-6xl font-extrabold text-left text-[#00AEEF]">
                Sell Gift Cards <br> Plus
                <font-awesome-icon icon="plus" size="2xs" style="color: #13338E;" />
                More.
            </h1>
            <p class="font-semibold text-left text-md">Best platform to sell your gift cards plus more at amazing rates with one
                click of the button. </p>
            <a class="text-white font-semibold w-fit bg-[#00AEEF] px-5 flex items-center gap-4 py-3 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none lg:mr-1 lg:mb-0 ease-linear transition-all duration-150"
                href="https://wa.me/2348146177544" target="_blank">
                Trade now
                <font-awesome-icon icon="fa-solid fa-arrow-right" style="color: #ffffff;" />
            </a>
        </div>
        <div>
            <img class="w-fit -z-10 md:float-right" src="@/assets/hero.png" alt="Hero">
        </div>
</section></template>