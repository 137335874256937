<template>
    <section class="bg-[#13338E] rounded-t-2xl py-10 px-5 md:px-20 text-white font-dm">
        <footer class="sticky bottom-0">
            <div class="grid grid-cols-1 md:grid-cols-2 gap-10">
                <div>
                    <router-link to="/">
                        <div class="mr-4 navbar-brand">
                            <img src="../assets/footer-logo.png" class="main-imasge w-fit" alt="cardplux logo">
                        </div>
                    </router-link>
                    <div class="mt-10">
                        <p class="text-start my-2">Get updates right to your Inbox</p>
                        <!-- <form action="#" method="post" class="flex flex-wrap gap-4">
                            <input required class="shadow appearance-none border bg-[#13338E] text-white rounded-md w-1/2 py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
                                id="email" type="email" placeholder="Email Address">
                            <button class="text-white cursor-pointer bg-[#00AEEF] px-4 flex items-center gap-4 py-2 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none lg:mb-0 ease-linear transition-all duration-150" type="submit">Subscribe</button>
                        </form> -->
                        <subscription-form />
                    </div>
                </div>
                <div class="flex flex-wrap gap-10 md:justify-end">
                    <ul class="flex flex-col gap-2 items-start">
                        <li class="mb-2 font-semibold">
                            Company
                        </li>
                        <li>
                            <a href="#" class="hover:text-[#00AEEF] text-sm">FAQ</a>
                        </li>
                        <li>
                            <a href="#" class="hover:text-[#00AEEF] text-sm">Contact</a>
                        </li>
                        <li>
                            <a href="#" class="hover:text-[#00AEEF] text-sm">Help/Support</a>
                        </li>
                        <li>
                            <a href="#" class="hover:text-[#00AEEF] text-sm">Our Team</a>
                        </li>
                    </ul>
                    <ul class="flex flex-col gap-2 items-start">
                        <li class="mb-2 font-semibold">
                            Other Links
                        </li>
                        <li>
                            <a href="#" class="hover:text-[#00AEEF] text-sm">Blog</a>
                        </li>
                    </ul>
                    <ul class="flex flex-col gap-2 items-start">
                        <li class="mb-2 font-semibold">
                            Account
                        </li>
                        <li>
                            <a href="#" class="hover:text-[#00AEEF] text-sm">Login</a>
                        </li>
                        <li>
                            <a href="#" class="hover:text-[#00AEEF] text-sm">Sign Up</a>
                        </li>
                        <li>
                            <a href="#" class="hover:text-[#00AEEF] text-sm">Forgot Password</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="grid grid-cols-1 md:grid-cols-2 my-5 gap-10">
                <div class="flex flex-wrap font-semibold gap-3">
                    <span>&copy; CARDPLUX {{ currentYear }} </span>
                    <span>Privacy Policy</span>
                    <span>Terms of use</span>
                </div>
                <!-- Socials -->
                <div class="flex flex-wrap items-end md:justify-end gap-6">
                    <a href="#">
                        <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <mask id="mask0_11_2198" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="30"
                                height="31">
                                <circle cx="15" cy="15.1689" r="15" fill="white" />
                            </mask>
                            <g mask="url(#mask0_11_2198)">
                                <path
                                    d="M21.9984 0.168945L15.5 -1.33105L8.9984 0.168945C7.3484 0.168945 0 6.76895 0 8.41895L-2 20.1689C-2 21.8189 7.34944 30.1689 8.99944 30.1689H21.9984C23.6484 30.1689 29 24.8189 29 23.1689L32 9.66895C32 8.01895 23.6484 0.168945 21.9984 0.168945ZM20.4984 3.16895V7.66895H17.4984C16.5984 7.66895 15.9984 8.26895 15.9984 9.16895V12.1689H20.4984V16.6689H15.9984V27.1689H11.4984V16.6689H8.4984V12.1689H11.4984V8.41895C11.4984 5.56894 13.8984 3.16895 16.7484 3.16895H20.4984Z"
                                    fill="white" />
                            </g>
                        </svg>
                    </a>
                    <a href="#">
                        <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <mask id="mask0_11_2201" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="30"
                                height="31">
                                <g clip-path="url(#clip0_11_2201)">
                                    <path
                                        d="M27.8364 0.168945H2.16362C0.968628 0.168945 0 1.13757 0 2.33257V28.0053C0 29.2003 0.968628 30.1689 2.16362 30.1689H27.8364C29.0314 30.1689 30 29.2003 30 28.0053V2.33257C30 1.13757 29.0314 0.168945 27.8364 0.168945V0.168945ZM10.6412 22.8447H6.98799V11.854H10.6412V22.8447ZM8.8147 10.3533H8.79089C7.565 10.3533 6.77216 9.50938 6.77216 8.4547C6.77216 7.37621 7.58926 6.55566 8.83896 6.55566C10.0887 6.55566 10.8577 7.37621 10.8815 8.4547C10.8815 9.50938 10.0887 10.3533 8.8147 10.3533ZM23.8138 22.8447H20.1611V16.965C20.1611 15.4873 19.6321 14.4796 18.3103 14.4796C17.3012 14.4796 16.7001 15.1593 16.436 15.8155C16.3394 16.0504 16.3158 16.3786 16.3158 16.707V22.8447H12.6629C12.6629 22.8447 12.7107 12.8852 12.6629 11.854H16.3158V13.4102C16.8013 12.6613 17.6699 11.5961 19.6081 11.5961C22.0116 11.5961 23.8138 13.1669 23.8138 16.5427V22.8447Z"
                                        fill="white" />
                                </g>
                            </mask>
                            <g mask="url(#mask0_11_2201)">
                                <circle cx="15" cy="15.1689" r="15" fill="white" />
                            </g>
                            <defs>
                                <clipPath id="clip0_11_2201">
                                    <rect width="30" height="30" fill="white" transform="translate(0 0.168945)" />
                                </clipPath>
                            </defs>
                        </svg>
                    </a>
                    <a href="#">
                        <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect y="0.168945" width="30" height="30" rx="15" fill="white" />
                            <g clip-path="url(#clip0_11_2207)">
                                <path
                                    d="M18.6689 7.16895H11.331C8.94287 7.16895 7 9.11181 7 11.5V18.838C7 21.226 8.94287 23.1689 11.331 23.1689H18.669C21.0571 23.1689 23 21.226 23 18.838V11.5C23 9.11181 21.0571 7.16895 18.6689 7.16895V7.16895ZM15 19.5438C12.5876 19.5438 10.6251 17.5813 10.6251 15.1689C10.6251 12.7566 12.5876 10.7941 15 10.7941C17.4123 10.7941 19.3749 12.7566 19.3749 15.1689C19.3749 17.5813 17.4123 19.5438 15 19.5438ZM19.4795 11.8258C18.7666 11.8258 18.1867 11.246 18.1867 10.5331C18.1867 9.82019 18.7666 9.24023 19.4795 9.24023C20.1924 9.24023 20.7723 9.82019 20.7723 10.5331C20.7723 11.246 20.1924 11.8258 19.4795 11.8258Z"
                                    fill="#13338E" />
                                <path
                                    d="M15.0009 11.7322C13.1058 11.7322 11.564 13.2739 11.564 15.1691C11.564 17.0641 13.1058 18.606 15.0009 18.606C16.896 18.606 18.4378 17.0641 18.4378 15.1691C18.4378 13.2739 16.896 11.7322 15.0009 11.7322Z"
                                    fill="#13338E" />
                                <path
                                    d="M19.4792 10.1782C19.2837 10.1782 19.1245 10.3374 19.1245 10.5329C19.1245 10.7285 19.2837 10.8877 19.4792 10.8877C19.6749 10.8877 19.8341 10.7286 19.8341 10.5329C19.8341 10.3373 19.6749 10.1782 19.4792 10.1782Z"
                                    fill="#13338E" />
                            </g>
                            <defs>
                                <clipPath id="clip0_11_2207">
                                    <rect width="16" height="16" fill="white" transform="translate(7 7.16895)" />
                                </clipPath>
                            </defs>
                        </svg>
                    </a>
                    <a href="#">
                        <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <mask id="mask0_11_2213" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="30"
                                height="31">
                                <g clip-path="url(#clip0_11_2213)">
                                    <path
                                        d="M27.8364 0.168945H2.16362C0.968628 0.168945 0 1.13757 0 2.33257V28.0053C0 29.2003 0.968628 30.1689 2.16362 30.1689H27.8364C29.0314 30.1689 30 29.2003 30 28.0053V2.33257C30 1.13757 29.0314 0.168945 27.8364 0.168945V0.168945ZM21.8488 11.8643C21.8555 12.012 21.8587 12.1603 21.8587 12.3093C21.8587 16.8588 18.3957 22.105 12.0628 22.1052H12.063H12.0628C10.1184 22.1052 8.3091 21.5353 6.78543 20.5587C7.05482 20.5905 7.32903 20.6063 7.60666 20.6063C9.21982 20.6063 10.7043 20.056 11.8829 19.1325C10.3757 19.1046 9.10492 18.1092 8.66638 16.7411C8.87627 16.7814 9.0921 16.8034 9.31343 16.8034C9.62769 16.8034 9.9321 16.761 10.2214 16.6821C8.64601 16.3667 7.45926 14.9744 7.45926 13.3072C7.45926 13.2917 7.45926 13.2777 7.45972 13.2633C7.92366 13.5212 8.45421 13.6764 9.01909 13.6938C8.09464 13.077 7.48695 12.0225 7.48695 10.828C7.48695 10.1972 7.65747 9.6062 7.95319 9.0974C9.65103 11.1807 12.1884 12.5508 15.0499 12.6947C14.9908 12.4425 14.9604 12.1797 14.9604 11.9097C14.9604 10.009 16.5024 8.46706 18.4037 8.46706C19.3941 8.46706 20.2885 8.88568 20.9168 9.55493C21.7012 9.40021 22.4377 9.11365 23.1031 8.71928C22.8456 9.52289 22.3 10.1972 21.5891 10.6236C22.2855 10.5403 22.9493 10.3556 23.5661 10.0814C23.1054 10.7719 22.5211 11.3784 21.8488 11.8643Z"
                                        fill="white" />
                                </g>
                            </mask>
                            <g mask="url(#mask0_11_2213)">
                                <circle cx="15" cy="15.1689" r="15" fill="white" />
                            </g>
                            <defs>
                                <clipPath id="clip0_11_2213">
                                    <rect width="30" height="30" fill="white" transform="translate(0 0.168945)" />
                                </clipPath>
                            </defs>
                        </svg>
                    </a>
                </div>
            </div>
        </footer>
    </section>
</template>
<script>
import SubscriptionForm from '@/components/SubscriptionForm.vue';
export default {
    data() {
        return {
            currentYear: new Date().getFullYear(),
            loading: false,
        };
    },
    components: {
    SubscriptionForm,
  },
};
</script>