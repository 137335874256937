import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import "@/index.css";

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faArrowRight, faBars, faPlus, faMinus, faBan, faSpinner } from '@fortawesome/free-solid-svg-icons'

/* add icons to the library */
library.add(faArrowRight, faBars, faPlus, faMinus, faBan, faSpinner)

import VueSmoothScroll from 'vue3-smooth-scroll'


createApp(App)
.component('font-awesome-icon', FontAwesomeIcon)
.use(router)
.use(VueSmoothScroll)
.mount('#app')

// createApp(App).use(router).mount("#app");
