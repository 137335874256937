<template>
    <section id="value" class="mx-5 md:mx-10 my-10">
        <div class="flex flex-col items-center justify-center gap-3">
            <h2 class="text-xl md:text-3xl font-extrabold uppercase text-[#00AEEF]">why choose cardplux?</h2>
            <svg width="115" height="5" viewBox="0 0 115 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 2.36377H115" stroke="#00AEEF" stroke-width="4" />
            </svg>
        </div>
        <div class="bg-[#E7F8FFB2] grid grid-cols-1 md:grid-cols-2 justify-center mt-20 mx:5  rounded-2xl">
            <div class="bg-[#00AEEF1A] grid items-center justify-center m-5 md:m-10 rounded-2xl py-10">
                <ul class="flex flex-col justify-start p-2 align-middle w-fit divide-y-2 divide-slate-400/25">
                    <li class="flex items-center gap-5 py-5 font-semibold text-lg">
                        <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_11_2224)">
                                <path
                                    d="M16.6667 38.3498C16.6667 38.7918 16.4911 39.2157 16.1785 39.5283C15.8659 39.8408 15.442 40.0164 15 40.0164H1.66667C1.22464 40.0164 0.800716 39.8408 0.488155 39.5283C0.175595 39.2157 0 38.7918 0 38.3498C0 37.9077 0.175595 37.4838 0.488155 37.1713C0.800716 36.8587 1.22464 36.6831 1.66667 36.6831H15C15.442 36.6831 15.8659 36.8587 16.1785 37.1713C16.4911 37.4838 16.6667 37.9077 16.6667 38.3498Z"
                                    fill="#CD52C8" />
                                <path
                                    d="M1.66667 33.3497H11.6667C12.1087 33.3497 12.5326 33.1741 12.8452 32.8615C13.1577 32.549 13.3333 32.1251 13.3333 31.683C13.3333 31.241 13.1577 30.8171 12.8452 30.5045C12.5326 30.192 12.1087 30.0164 11.6667 30.0164H1.66667C1.22464 30.0164 0.800716 30.192 0.488155 30.5045C0.175595 30.8171 0 31.241 0 31.683C0 32.1251 0.175595 32.549 0.488155 32.8615C0.800716 33.1741 1.22464 33.3497 1.66667 33.3497Z"
                                    fill="#CD52C8" />
                                <path
                                    d="M1.66667 26.6829H8.33333C8.77536 26.6829 9.19928 26.5073 9.51184 26.1948C9.8244 25.8822 10 25.4583 10 25.0163C10 24.5742 9.8244 24.1503 9.51184 23.8378C9.19928 23.5252 8.77536 23.3496 8.33333 23.3496H1.66667C1.22464 23.3496 0.800716 23.5252 0.488155 23.8378C0.175595 24.1503 0 24.5742 0 25.0163C0 25.4583 0.175595 25.8822 0.488155 26.1948C0.800716 26.5073 1.22464 26.6829 1.66667 26.6829Z"
                                    fill="#CD52C8" />
                                <path
                                    d="M20 0.0163574C14.6957 0.0163574 9.60859 2.12349 5.85786 5.87422C2.10714 9.62495 0 14.712 0 20.0164C0 20.118 0.0133333 20.2164 0.015 20.318C0.544326 20.124 1.10294 20.022 1.66667 20.0164H8.33333C9.1515 20.0136 9.95776 20.2123 10.6809 20.595C11.4041 20.9776 12.0219 21.5324 12.4799 22.2104C12.9378 22.8885 13.2217 23.6688 13.3066 24.4825C13.3915 25.2963 13.2748 26.1184 12.9667 26.8764C13.6491 27.0554 14.2854 27.3781 14.8332 27.8227C15.3809 28.2674 15.8275 28.8238 16.143 29.4549C16.4585 30.0859 16.6357 30.777 16.6628 31.482C16.6899 32.187 16.5662 32.8896 16.3 33.543C16.9665 33.713 17.5899 34.0211 18.1299 34.4472C18.6699 34.8733 19.1144 35.4081 19.4348 36.0168C19.7551 36.6255 19.944 37.2947 19.9894 37.9811C20.0348 38.6675 19.9357 39.3557 19.6983 40.0014C19.8 40.0014 19.8983 40.0164 20 40.0164C25.3043 40.0164 30.3914 37.9092 34.1421 34.1585C37.8929 30.4078 40 25.3207 40 20.0164C40 14.712 37.8929 9.62495 34.1421 5.87422C30.3914 2.12349 25.3043 0.0163574 20 0.0163574ZM26.1783 26.1947C25.8658 26.5071 25.4419 26.6827 25 26.6827C24.5581 26.6827 24.1342 26.5071 23.8217 26.1947L18.8217 21.1947C18.5091 20.8822 18.3334 20.4583 18.3333 20.0164V11.683C18.3333 11.241 18.5089 10.8171 18.8215 10.5045C19.134 10.192 19.558 10.0164 20 10.0164C20.442 10.0164 20.8659 10.192 21.1785 10.5045C21.4911 10.8171 21.6667 11.241 21.6667 11.683V19.3264L26.1783 23.838C26.4908 24.1506 26.6663 24.5744 26.6663 25.0164C26.6663 25.4583 26.4908 25.8821 26.1783 26.1947Z"
                                    fill="#CD52C8" />
                            </g>
                            <defs>
                                <clipPath id="clip0_11_2224">
                                    <rect width="40" height="40" fill="white" transform="translate(0 0.0163574)" />
                                </clipPath>
                            </defs>
                        </svg>
                        Blazing Transaction Speed
                    </li>
                    <li class="flex items-center gap-5 py-5 font-semibold text-lg">
                        <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_11_2232)">
                                <path
                                    d="M39.9999 20.0163C39.9999 17.3063 38.8099 14.7497 36.7916 13.143C37.0249 10.4397 36.0582 7.79133 34.1416 5.873C32.2249 3.958 29.5832 2.988 27.0132 3.28133C23.6566 -0.97867 16.4049 -1.05867 13.1266 3.223C7.73825 2.583 2.55658 7.65633 3.26658 13.003C-0.993421 16.3597 -1.07509 23.6113 3.20825 26.8913C2.97491 29.5947 3.94158 32.243 5.85825 34.1613C7.77491 36.0763 10.4199 37.0463 12.9866 36.753C16.3432 41.013 23.5949 41.093 26.8732 36.8113C29.5732 37.043 32.2232 36.0797 34.1416 34.1613C36.0566 32.2447 37.0232 29.5947 36.7332 27.033C38.8099 25.2863 39.9999 22.7297 39.9999 20.0197V20.0163ZM13.3332 15.0163C13.3432 12.8363 16.6566 12.8363 16.6666 15.0163C16.6566 17.1963 13.3432 17.1963 13.3332 15.0163ZM18.0532 25.9413C17.5399 26.713 16.4982 26.9097 15.7416 26.403C14.9749 25.893 14.7682 24.858 15.2799 24.0913L21.9466 14.0913C22.4566 13.3263 23.4899 13.1163 24.2582 13.6297C25.0249 14.1397 25.2316 15.1747 24.7199 15.9413L18.0532 25.9413ZM24.9999 26.683C22.8199 26.673 22.8199 23.3597 24.9999 23.3497C27.1799 23.3597 27.1799 26.673 24.9999 26.683Z"
                                    fill="#00AEEF" />
                            </g>
                            <defs>
                                <clipPath id="clip0_11_2232">
                                    <rect width="40" height="40" fill="white" transform="translate(0 0.0163574)" />
                                </clipPath>
                            </defs>
                        </svg>

                        Zero Withdrawal Charges
                    </li>
                    <li class="flex items-center gap-5 py-5 font-semibold text-lg">
                        <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M8.33333 36.6829C9.21739 36.6829 10.0652 36.3318 10.6904 35.7066C11.3155 35.0815 11.6667 34.2337 11.6667 33.3496V23.3496C11.6667 22.4656 11.3155 21.6177 10.6904 20.9926C10.0652 20.3675 9.21739 20.0163 8.33333 20.0163V18.3496C8.33333 15.2554 9.56249 12.288 11.7504 10.1C13.9383 7.9121 16.9058 6.68294 20 6.68294C23.0942 6.68294 26.0617 7.9121 28.2496 10.1C30.4375 12.288 31.6667 15.2554 31.6667 18.3496V20.0163C30.7826 20.0163 29.9348 20.3675 29.3096 20.9926C28.6845 21.6177 28.3333 22.4656 28.3333 23.3496V33.3496H23.3333C22.8913 33.3496 22.4674 33.5252 22.1548 33.8378C21.8423 34.1503 21.6667 34.5742 21.6667 35.0163C21.6667 35.4583 21.8423 35.8822 22.1548 36.1948C22.4674 36.5073 22.8913 36.6829 23.3333 36.6829H31.6667C33.5767 36.6772 35.4268 36.0156 36.9072 34.8087C38.3876 33.6018 39.4086 31.923 39.7991 30.0533C40.1896 28.1837 39.9259 26.2366 39.0523 24.5381C38.1787 22.8395 36.7481 21.4927 35 20.7229V18.3496C35 14.3714 33.4196 10.5561 30.6066 7.74301C27.7936 4.92996 23.9782 3.34961 20 3.34961C16.0217 3.34961 12.2064 4.92996 9.3934 7.74301C6.58035 10.5561 5 14.3714 5 18.3496V20.7229C3.25193 21.4927 1.82133 22.8395 0.947702 24.5381C0.0740701 26.2366 -0.189566 28.1837 0.200928 30.0533C0.591423 31.923 1.61234 33.6018 3.09278 34.8087C4.57322 36.0156 6.42331 36.6772 8.33333 36.6829Z"
                                fill="#1B1B1B" />
                        </svg>
                        24/7 Uptime & Support
                    </li>
                    <li class="flex items-center gap-5 py-5 font-semibold text-lg">
                        <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_11_2247)">
                                <path
                                    d="M33.2399 4.41958L19.9999 0.03125L6.75992 4.41958C5.76237 4.75028 4.89439 5.38688 4.27926 6.23897C3.66412 7.09107 3.33311 8.11532 3.33325 9.16625V20.0163C3.33325 32.5563 18.6666 39.4812 19.3233 39.7696L19.9133 40.0312L20.5266 39.8279C21.1866 39.6079 36.6666 34.3112 36.6666 20.0163V9.16625C36.6667 8.11532 36.3357 7.09107 35.7206 6.23897C35.1054 5.38688 34.2375 4.75028 33.2399 4.41958ZM20.7399 23.3313C20.4486 23.6246 20.1018 23.8571 19.7199 24.0153C19.3379 24.1735 18.9283 24.2543 18.5149 24.2529H18.4599C18.0381 24.2465 17.6219 24.1545 17.2366 23.9826C16.8513 23.8107 16.5049 23.5625 16.2183 23.2529L12.3749 19.2529L14.7799 16.9496L18.5199 20.8496L27.1549 12.2146L29.5116 14.5712L20.7399 23.3313Z"
                                    fill="#534BEC" />
                            </g>
                            <defs>
                                <clipPath id="clip0_11_2247">
                                    <rect width="40" height="40" fill="white" transform="translate(0 0.0163574)" />
                                </clipPath>
                            </defs>
                        </svg>
                        100% Safe & Secure
                    </li>
                    <li class="flex items-center gap-5 py-5 font-semibold text-lg">
                        <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_11_2242)">
                                <path
                                    d="M40 38.3497C40 39.2697 39.2533 40.0164 38.3333 40.0164H5C2.24333 40.0164 0 37.773 0 35.0164V1.68302C0 0.763024 0.746667 0.0163574 1.66667 0.0163574C2.58667 0.0163574 3.33333 0.763024 3.33333 1.68302V35.0164C3.33333 35.9347 4.08167 36.683 5 36.683H38.3333C39.2533 36.683 40 37.4297 40 38.3497ZM35 8.34969H28.3333C27.4133 8.34969 26.6667 9.09636 26.6667 10.0164C26.6667 10.9364 27.4133 11.683 28.3333 11.683H34.2717L26.1767 19.528C25.5483 20.158 24.45 20.158 23.8217 19.528C23.7533 19.4614 21.7567 18.0397 21.7567 18.0397C19.86 16.2514 16.6283 16.318 14.82 18.1247L7.17667 25.4814C6.51333 26.1197 6.49333 27.1747 7.13167 27.838C7.46 28.178 7.895 28.3497 8.33333 28.3497C8.75 28.3497 9.16667 28.1947 9.48833 27.8847L17.155 20.5047C17.7833 19.8747 18.8817 19.8747 19.51 20.5047C19.5783 20.5714 21.575 21.993 21.575 21.993C23.4733 23.783 26.7017 23.7197 28.515 21.903L36.665 14.003V20.0147C36.665 20.9347 37.4117 21.6814 38.3317 21.6814C39.2517 21.6814 39.9983 20.9347 39.9983 20.0147V13.348C39.9983 10.5914 37.755 8.34802 34.9983 8.34802L35 8.34969Z"
                                    fill="#E58642" />
                            </g>
                            <defs>
                                <clipPath id="clip0_11_2242">
                                    <rect width="40" height="40" fill="white" transform="translate(0 0.0163574)" />
                                </clipPath>
                            </defs>
                        </svg>
                        Super High Rates
                    </li>
                </ul>
            </div>
        <div class="p-10">
            <img class=" z-10" src="@/assets/value.png" alt="value">
        </div>
    </div>
</section></template>