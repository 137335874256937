<template>
    <form @submit.prevent="subscribe" class="flex flex-wrap gap-4">
        <input required v-model="email"
            class="shadow appearance-none border bg-[#13338E] text-white rounded-md w-1/2 py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
            id="email" type="email" placeholder="Email Address">
        <button type="submit" :disabled="loading"
            class="text-white cursor-pointer bg-[#00AEEF] px-4 flex items-center gap-4 py-2 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none lg:mb-0 ease-linear transition-all duration-150">
            <span v-if="!loading">Subscribe</span>

            <span v-else>Subscribing
                <font-awesome-icon :icon="['fas', 'spinner']" spin style="color: #13338E;" />
            </span>
        </button>
    </form>
</template>
  
<script>
import axios from 'axios';
import Swal from 'sweetalert2';
export default {
    data() {
        return {
            email: '',
            loading: false,
        };
    },
    methods: {
        subscribe() {
            if (this.loading) {
                return; // Prevent multiple submissions while loading
            }
            //   const apiKey = process.env.VUE_APP_SENDGRID_API_KEY;
            const apiKey = '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';
            const endpoint = `https://api.sendgrid.com/v3/marketing/contacts`;

            this.loading = true; // Set loading state to true

            axios.put(
                endpoint,
                {
                    contacts: [
                        {
                            email: this.email,
                        },
                    ],
                },
                {
                    headers: {
                        Authorization: `Bearer ${apiKey}`,
                        'Content-Type': 'application/json',
                    },
                }
            )
                .then(() => {
                    Swal.fire({
                        icon: 'success',
                        title: 'Subscription Successful!',
                        text: 'Thank you for subscribing to our newsletter.',
                        timer: 2000,
                    });
                })
                .catch((error) => {
                    Swal.fire({
                        icon: 'error',
                        title: 'Subscription Failed!',
                        text: 'Subscription failed. Please try again.',
                        timer: 2000,
                    });
                })
                .finally(() => {
                    this.email = '',
                        this.loading = false; // Reset loading state to false
                });
        },
    }
};
</script>