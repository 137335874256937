<template>
    <section id="products" class="mx-5 md:mx-10 my-10">
        <div class="flex flex-col items-center justify-center gap-3">
        <h2 class="text-xl md:text-2xl font-extrabold uppercase text-[#00AEEF]">Our Products</h2>
        <svg width="115" height="5" viewBox="0 0 115 5" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 2.36377H115" stroke="#00AEEF" stroke-width="4" />
        </svg>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-2 mt-5 gap-10">
        <!-- Product one -->
        <div class="bg-[#00AEEF] text-white text-start w-full h-max-[656px] rounded-md px-10 pt-10">
            <h3 class=" text-3xl font-extrabold uppercase  ">Sell Gift Cards</h3>
            <p class="font-dm w-3/4 my-4">Sell your gift cards for Naira with one click of the button. Enjoy safe and convenient trading with the best rates.</p>
            <ul class="font-dm">
                <li>
                    <font-awesome-icon icon="ban" style="color: #e9ecf1;" />
                    No Sign-up needed!
                </li>
                <li>
                    <font-awesome-icon icon="ban" style="color: #e9ecf1;" />
                    No Stress!
                </li>
            </ul>
            <div class="flex align-middle justify-center">
                <img class="w-1/2 z-10" src="@/assets/p1.png" alt="hand with card">
            </div>
        </div>
        <!-- Product two -->
        <div class="bg-[#CAF1FF] text-[#1B1B1B]  flex flex-col text-start justify-between w-full h-max-[656px] rounded-md px-10 pt-10">
            <div>
                <h3 class=" text-3xl font-extrabold uppercase  ">BUY Gift Cards</h3>
            <p class="font-dm w-3/4 my-4">Buy over 200+ local and international gift cards instantly at affordable rates.</p>
            </div>
            <div class="flex align-middle justify-center">
                <img class="w-1/2 z-10" src="@/assets/p2.png" alt="hand with card">
            </div>
        </div>
        <!-- Product three -->
        <div class="bg-[#FFF0FE] text-[#1B1B1B] flex flex-col text-start justify-between w-full h-max-[656px] rounded-md px-10 pt-10">
            <div>
                <h3 class=" text-3xl font-extrabold uppercase  ">BUY AIRTIME & DATA</h3>
            <p class="font-dm w-3/4 my-4">Buy instant airtime one tip of the finger. Recharge your phone hassle-free in under 60 seconds and stay connected wherever you are.</p>
            </div>
            <div class="flex justify-center">
                <img class="w-1/2 z-10" src="@/assets/p3.png" alt="airtime and data">
            </div>
        </div>
        <!-- Product four -->
        <div class="bg-[#FFE8D7] text-[#1B1B1B] text-start w-full h-max-[656px] rounded-md px-10 pt-10">
            <h3 class=" text-3xl font-extrabold uppercase  ">PAY BILLS</h3>
            <p class="font-dm w-3/4 my-4">Pay all your bills from the comfort of your home and say goodbye to the stress of long queues.</p>
            <img class="w-20" src="@/assets/coming-soon.png" alt="coming soon">
            <div class="flex align-middle justify-center">
                <img class="w-1/2 z-10" src="@/assets/p4.png" alt="pay bills">
            </div>
        </div>
    </div>
    </section>
</template>